const ENV_BASE_URL =
  "https://d21fbyier8.execute-api.us-east-1.amazonaws.com/api/v1/";
const ENV_BASE_URL_V2 =
  "https://d21fbyier8.execute-api.us-east-1.amazonaws.com/api/v2/";
const ENV_SERVER_AUTH_USERNAME = "qfrsvoesnsyswcymj";
const ENV_SERVER_AUTH_PASSWORD = "I.5=4!tCBBA{c@T-@2$";
const ENV_NODE_LAMBDA =
  "https://1d1x86mdmd.execute-api.us-east-1.amazonaws.com/dev/";
const ENV_IMAGE_URL =
  "https://circadn5c3ef53d0ecb465fbc5b20293d2e9f5a04153-production.s3.amazonaws.com/public/";
const ENV_BASE_URL_WEB = "https://portal.coachfirst.com/api/v1/";

export const BASE_URL = ENV_BASE_URL;
export const BASE_URL_V2 = ENV_BASE_URL_V2;
export const BASE_URL_WEB = ENV_BASE_URL_WEB;

// export const SERVER_AUTH = {
//   username: ENV_SERVER_AUTH_USERNAME,
//   password: ENV_SERVER_AUTH_PASSWORD,
// };
export const NODE_LAMBDA = ENV_NODE_LAMBDA;
export const IMAGE_URL = ENV_IMAGE_URL;

export const PRIMARY_COLOR = "#c82638";

export const TNC_PRIVACY = "https://www.coachfirst.com/docs/privacypolicy.pdf";
export const TNC_TERMS = "https://www.coachfirst.com/docs/terms.pdf";
