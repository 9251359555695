import React from "react";
import { useSelector } from "react-redux";

export default function CalendarFms({ data, isCalendar = true }) {
  const authDetails = useSelector((state) => state.auth);

  const isSessionAndFmListMore =
    data.typename === "appointment" || data?.familyMembers?.length > 0;

  if (isSessionAndFmListMore) {
    return (
      <span className={`info ${isCalendar ? "_smTxt" : ""}`}>
        {isCalendar && (
          <i className="far fa-calendar-check" style={{ color: "green" }}></i>
        )}
        {data.typename === "appointment"
          ? `${data?.memberFirstName} ${
              data?.memberLastName ? data?.memberLastName : ""
            }`
          : data?.familyMembers?.map(
              (item, index) =>
                `${
                  item?.isParent
                    ? authDetails?.companyData?.member?.firstName
                    : item?.firstName
                }${data?.familyMembers?.length - 1 !== index ? ", " : ""}`
            )}
      </span>
    );
  } else {
    return null;
  }
}
