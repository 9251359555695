import { toast } from "react-toastify";
import PackUI from "./PackUI";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedPackage } from "../../../redux/actions";

const CampPacks = ({ selectedPackage, campPacksList }) => {
  const bookingDetails = useSelector((state) => state.booking);
  const {
    repeat_id,
    allow_book_individually,
    one_time_fee,
    start_branch_time,
  } = bookingDetails?.classDetails || {};
  const dispatch = useDispatch();
  return (
    <>
      {repeat_id && allow_book_individually && (
        <>
          <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
            Please select if you would like to book all the days of the event or
            just the present day?
          </p>

          <div className="row row-cols-1 row-cols-md-4 mb-3 text-center">
            {campPacksList.map((pack) => (
              <PackUI
                {...pack}
                key={pack.label}
                _handleOnClick={() => dispatch(addSelectedPackage(pack))}
                isSelected={pack.campBookType === selectedPackage?.campBookType}
                isShowBookingDate={pack.campBookType === "single"}
                bookingDate={
                  // show date for the single pack if packs are showing for multi-day camps
                  moment(start_branch_time).format("MMMM Do")
                }
                coupon={bookingDetails?.couponCode?.couponDetails}
              />
            ))}
          </div>
          {one_time_fee && (
            <div className="pl-3">
              <input
                className="form-check-input"
                type="checkbox"
                id={`check1`}
                checked={selectedPackage?.isOneTimeMembershipFeeEnabled}
                onChange={() => {
                  if (!selectedPackage?.price) {
                    toast.error("You need to select at-least one package!");
                    dispatch(
                      addSelectedPackage({
                        ...selectedPackage,
                        isOneTimeMembershipFeeEnabled: false,
                      })
                    );
                    return;
                  }
                  dispatch(
                    addSelectedPackage({
                      ...selectedPackage,
                      isOneTimeMembershipFeeEnabled:
                        !selectedPackage?.isOneTimeMembershipFeeEnabled,
                    })
                  );
                }}
              />
              <p>
                There is a member fee of $ {one_time_fee}, please check this box
                if you have not paid the membership fee yet
              </p>
            </div>
          )}
        </>
      )}

      {!repeat_id && (
        <>
          <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
            Packages
          </p>
          <div className="row row-cols-1 row-cols-md-4 mb-3 text-center">
            <PackUI
              {...selectedPackage}
              coupon={bookingDetails?.couponCode?.couponDetails}
            />
          </div>
        </>
      )}

      {repeat_id && !allow_book_individually && (
        <>
          <p className="text-dark bg-light text-left pl-3 py-2 my-3 text-dark fw-normal">
            All the days of the event will be booked
          </p>
          <div className="row row-cols-1 row-cols-md-4 mb-3 text-center">
            <PackUI
              {...selectedPackage}
              coupon={bookingDetails?.couponCode?.couponDetails}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CampPacks;
