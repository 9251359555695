import "./PackUI.scss";
const PackUI = (props) => {
  const {
    label,
    price,
    quantity,
    isSelected,
    isShowBookingDate,
    bookingDate,
    coupon,
  } = props || {};
  const packLabel = `${label} ${isShowBookingDate ? `for ${bookingDate}` : ""}`;
  let discountedPrice;
  if (coupon?.percent) {
    discountedPrice = (price - (price * coupon?.percent) / 100)?.toFixed(2);
  }
  let decimalPrice = parseFloat(price);
  decimalPrice = Number.isInteger(decimalPrice)
    ? decimalPrice
    : decimalPrice?.toFixed(2);

  return (
    <div className="col">
      <div
        className={`card mb-4 rounded-3 shadow-sm ${
          isSelected ? "selected" : ""
        }`}
        role="button"
        onClick={props._handleOnClick}
      >
        <div className="card-header py-3 bg-dark">
          <h4 className="my-0 fw-normal text-white fs-5 pack-card">
            {packLabel}
          </h4>
        </div>
        <div className="card-body">
          <h2
            className={
              "pricing-card-title mb-0" +
              (coupon && " text-decoration-line-through text-danger")
            }
          >
            ${decimalPrice}
          </h2>
          <p className="font-size-12">(includes all fees)</p>
          {coupon && (
            <h2 className="pricing-card-title mb-0"> ${discountedPrice}</h2>
          )}
          {quantity && (
            <ul className="list-unstyled mt-3 mb-4">
              <li className="p-1 border-bottom border-top">
                {`${quantity} Session${quantity > 1 ? "s" : ""}`}
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackUI;
