import React, { useEffect, useState, useRef } from "react";
import { Auth } from "aws-amplify";
import useDetectOutside from "../../hooks/useDetectOutside";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPublicUser,
  logoutUser,
  changeSessionPage,
  handleBranchChange,
  handleConnectToBranch,
  clearClassBookingDetails,
  clearAppointmentBookingDetails,
  updateCompanyMemberTnc,
} from "../../redux/actions/index";
import moment from "moment-timezone";
import { getInitials } from "../../utility/helperFunctions";
import { IMAGE_URL, NODE_LAMBDA, PRIMARY_COLOR } from "../../utility/constants";
import axios from "axios";
import { Dropdown } from "react-bootstrap";
import { AcceptTC, AppLoader } from "..";
import "./Header.css";
import { Button, Modal } from "antd";
import { RiErrorWarningLine } from "react-icons/ri";
import { apiPostAcceptTnc } from "./utils";
import _ from "lodash";

const Header = () => {
  const initialRender = useRef(true);

  const dispatch = useDispatch();
  const location = useLocation();
  const notification = useSelector((state) => state.notification);
  const authDetails = useSelector((state) => state.auth);
  const booking = useSelector((state) => state.booking);
  const publicUser = useSelector((state) => state.publicUser);
  const history = useHistory();
  const [activeCompany, setActiveCompany] = useState({});
  const [activeBranch, setActiveBranch] = useState({});
  const [companyChangeLoading, setCompanyChangeLoading] = useState(false);
  const [tnc, setTnc] = useState({
    tnc18Plus: false,
    tncPrivacy: false,
    tncTerms: false,
    isShowModal: false,
    isLoading: false,
  });

  useEffect(() => {
    document.body.setAttribute("class", "sidebar-enable vertical-collpsed");
    const activeCompanyData =
      authDetails.companyData?.member_companies?.filter(
        (item) => item?.id === authDetails.companyData?.id
      ) || [];
    const activeBranchData =
      activeCompanyData[0]?.branches?.filter(
        (item) => item?.id === authDetails.companyData?.branch?.id
      ) || [];

    setActiveCompany(activeCompanyData[0]);
    setActiveBranch(activeBranchData[0]);

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (
        booking.service &&
        booking.trainer &&
        booking.session_type === "PUBLIC_APPOINTMENT_BOOKING" &&
        publicUser?.companyId &&
        publicUser.branchSelected
      ) {
        if (
          authDetails?.companyData?.branch?.id ===
          publicUser?.branchSelected?.id
        ) {
          dispatch(clearPublicUser());
          return;
        }
        const branch = authDetails?.companyData?.member_branches?.filter(
          (item) =>
            item?.memberBranchesBranchId === publicUser?.branchSelected?.id
        );

        if (branch?.length) {
          handleBranch(
            { id: publicUser.companyId },
            publicUser?.branchSelected,
            true
          );
        } else
          dispatch(
            handleConnectToBranch({
              username: authDetails?.authDetails?.username,
              branchId: publicUser?.branchSelected?.id,
            })
          );
        dispatch(clearPublicUser());
      }
    }
  }, [authDetails.companyData]);

  const profileDropRef = useRef(null);
  const notificationDropRef = useRef(null);

  const [showProfileDropdown, setShowProfileDropDown] = useDetectOutside(
    profileDropRef,
    false
  );
  const [showNotificationDropdown, setShowNotificationDropDown] =
    useDetectOutside(notificationDropRef, false);

  const [notificationSeen, setNotificationSeen] = useState(false);

  const handleBranch = async (company, branch = null, publicBooking = null) => {
    console.log("___location__", location);
    if (location.pathname === "/booking" && !publicBooking) {
      dispatch(changeSessionPage(null));
    }
    setCompanyChangeLoading(true);
    const payload = {
      companyId: company?.id,
      branchId: branch ? branch?.id : company?.branches[0]?.id,
      username: authDetails?.authDetails?.username,
    };
    dispatch(handleBranchChange(payload));
    setCompanyChangeLoading(false);
  };

  const handleCompanyChange = async (company) => {
    setActiveCompany(company);
    handleBranch(company);
  };

  const updateNotification = () => {
    const data = {
      receiverId: authDetails.companyData?.member?.id,
      isCleared: "false",
    };
    setTimeout(() => {
      axios
        .put(NODE_LAMBDA + "updateIsRead", data)
        .then((data) => {
          console.log("response received data is", data);
          setNotificationSeen(true);
        })
        .catch((error) => {
          console.log("something error", error);
        });
    }, 1000);
  };

  const setTncState = (type) => (value) => {
    setTnc((p) => ({
      ...p,
      [type]: value,
    }));
  };

  const toggleTncModal = () => setTnc((p) => ({ isShowModal: !p.isShowModal }));

  const _acceptTnc = async () => {
    setTnc((p) => ({ ...p, isLoading: true }));
    const { status } = await apiPostAcceptTnc({ timezone: moment.tz.guess() });
    if (status === 200) {
      dispatch(updateCompanyMemberTnc());
      toggleTncModal();
    } else {
      setTnc((p) => ({ ...p, isLoading: false }));
      // show alert
      Modal.error({
        title: "Error",
        content:
          "Something went wrong. Please try again. If the problem persists, please contact CoachFirst at info@coachfirst.com",
      });
    }
  };

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header position-relative">
          <div className="cf-logo pl-4 d-none d-sm-block">
            {/* <Link to="/" className="logo">
            <img src={logoLight} alt="" />
          </Link> */}
            {/* <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src="assets/images/fav.png" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="assets/images/logo-dark.png" alt="" height="17" />
              </span>
            </Link>



              <span className="logo-lg">

                <div className="navbar-pro-info">
                  {authDetails.companyData?.imageUrl ? (
                    <span>
                      <img
                        className="rounded-circle mt-5"
                        src={IMAGE_URL + authDetails.companyData?.imageUrl}
                        alt=""
                        height="50"
                        width="50"
                        style={{ border: "2px solid white" }}
                      />
                    </span>
                  ) : null}

                  <span className="logo-lg">
                    <h4 className="_text-white pt-2">
                      {authDetails.companyData?.name}
                    </h4>
                  </span>
                  <span className="text-primary _text-white text-center">
                    {authDetails.companyData?.branch?.name}
                  </span>
                </div>
              </span>
            </Link>
          </div>
          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item waves-effect"
            id="vertical-menu-btn"
            onClick={() => {
              setLeftSideBarShow(!leftSideBarShow);
              handleLeftBarShow(!leftSideBarShow);
            }}
          >
            <i className="fa fa-fw fa-bars"></i>
          </button> */}
            {/* <!-- App Search--> */}
            {/* <form className="app-search d-none d-lg-block">
            <div className="position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
              />
              <span className="bx bx-search-alt"></span>
            </div>
          </form> */}
          </div>
          {authDetails?.companyDataLoadingStatus || companyChangeLoading ? (
            <AppLoader height={80} width={80} />
          ) : (
            <>
              <div className="external-login-hdr company_logo top-50 start-50 ">
                {authDetails?.companyData?.id && (
                  <Dropdown>
                    <div className="row">
                      <div className="col-12">
                        <div className="text-center">
                          <Link to="/" className="logo logo-light">
                            {authDetails.companyData?.imageUrl && (
                              <span className="logo-sm mr-3">
                                <img
                                  src={
                                    IMAGE_URL +
                                    authDetails.companyData?.imageUrl
                                  }
                                  alt=""
                                  className="img-fluid ex-logo"
                                  // height="30"
                                  width="150"
                                />
                              </span>
                            )}
                            <span className="text-dark name">
                              {authDetails.companyData?.name}
                            </span>
                          </Link>
                          <Dropdown.Toggle
                            id="company_dropdown"
                            className="toggle"
                          >
                            <i
                              className="fas fa-chevron-down user-dropdown"
                              aria-hidden="true"
                            ></i>
                          </Dropdown.Toggle>
                        </div>
                      </div>
                    </div>

                    <Dropdown.Menu className="company dropdown-menu show py-4">
                      {authDetails?.companyData?.member_companies?.map(
                        (item, index) => (
                          <Dropdown.Item
                            className={`dropdown-item border-bottom p-4 px-3 ${
                              item?.branches?.length !== 1 && "mult-address"
                            }`}
                            key={index}
                            onClick={() => handleCompanyChange(item)}
                            active={activeCompany?.id === item?.id}
                          >
                            {item?.imageUrl && (
                              <img
                                src={IMAGE_URL + item?.imageUrl}
                                alt=""
                                className="img-fluid ex-logo drop-item-img mr-3"
                                // height="30"
                                width="60"
                              />
                            )}

                            <address className="d-inline-block mb-0">
                              <strong>{item?.name}</strong>
                              {item?.branches?.length === 1 && (
                                <div>
                                  <i
                                    className="fa fa-map-marker mr-2"
                                    aria-hidden="true"
                                  ></i>
                                  {item?.branches[0]?.street},{" "}
                                  {item?.branches[0]?.state}, <br />
                                  {item?.branches[0]?.city},{" "}
                                  {item?.branches[0]?.zip}
                                </div>
                              )}
                            </address>
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
              {activeCompany?.branches?.length > 1 && (
                <div className="external-login-hdr second-dropdown company_logo  top-50 start-50">
                  <Dropdown>
                    {/* <div className="row"> */}
                    <div className="col-12">
                      <div className="text-center">
                        <div className="d-inline-block">
                          <span className="text-dark text-truncate">
                            {activeBranch?.name}
                          </span>
                          <Dropdown.Toggle
                            id="company_dropdown"
                            className="toggle"
                          >
                            <i
                              className="fas fa-chevron-down user-dropdown"
                              aria-hidden="true"
                            ></i>
                          </Dropdown.Toggle>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}

                    <Dropdown.Menu className="company dropdown-menu show py-4">
                      {activeCompany?.branches?.map((item, index) => (
                        <Dropdown.Item
                          className="dropdown-item border-bottom p-4"
                          key={index}
                          onClick={() => handleBranch(activeCompany, item)}
                          active={activeBranch?.id === item?.id}
                        >
                          <img
                            src={IMAGE_URL + item?.imageUrl}
                            alt=""
                            className="img-fluid ex-logo"
                            // height="30"
                            width="40"
                          />
                          <span className="ml-3">{item?.name}</span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </>
          )}
          <div className="d-flex">
            <div className="dropdown d-inline-block ms-2">
              {/* <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => history.push("/chat")}
            >
              <i className="bx bx-message"></i>
            </button> */}
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <div className="dropdown d-none d-lg-inline-block ms-1">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              data-toggle="fullscreen"
            >
              <i className="bx bx-fullscreen"></i>
            </button>
          </div> */}
            <div className="dropdown d-inline-block">
              <button
                ref={notificationDropRef}
                type="button"
                className={
                  showNotificationDropdown
                    ? "btn header-item noti-icon waves-effect show"
                    : "btn header-item noti-icon waves-effect"
                }
                id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup={true}
                aria-expanded={false}
                onClick={() => {
                  setShowNotificationDropDown(!showNotificationDropdown);
                  notification?.notifications?.length > 0 &&
                    updateNotification();
                }}
              >
                <i className="bx bx-bell bx-tada"></i>

                {!notificationSeen && notification.count !== 0 && (
                  <span className="badge bg-danger rounded-pill">
                    {notification.count > 9 ? "9+" : notification.count}
                  </span>
                )}
              </button>
              <div
                className={
                  showNotificationDropdown
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-notifications-dropdown"
              >
                <div className="p-3">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="m-0" key="t-notifications">
                        Notifications
                      </h6>
                    </div>
                    {/* <div className="col-auto">
                    <a href="#!" className="small" key="t-view-all">
                      {" "}
                      View All
                    </a>
                  </div> */}
                  </div>
                </div>
                <div data-simplebar className="p-3">
                  <div className="_up-eventWrapper">
                    <div className="table-responsive">
                      {notification?.notifications?.length > 0 ? (
                        <table className="table table-nowrap align-middle ">
                          <tbody>
                            {notification.notifications.map((item, index) => (
                              <tr
                                key={index}
                                className="text-reset notification-item"
                              >
                                <div className="media">
                                  {/* <div className="avatar-xs me-3">
                                <span className="avatar-title bg-primary rounded-circle font-size-16">
                                  <i className="bx bx-cart"></i>
                                </span>
                              </div> */}
                                  <div className="media-body">
                                    {/* <h6 className="mt-0 mb-1" key="t-your-order">
                                  Your order is placed
                                </h6> */}
                                    <div className="font-size-12 ">
                                      <p
                                        className="mb-1"
                                        key="t-grammer"
                                        style={{
                                          fontSize: "14px",
                                          color: "#14141e",
                                        }}
                                      >
                                        {item.text}
                                      </p>
                                      <p className="mb-0">
                                        <i className="mdi mdi-clock-outline"></i>{" "}
                                        <span key="t-min-ago text-muted">
                                          {moment(item.createdAt).fromNow(true)}{" "}
                                          ago
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p>You have no new notifications!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dropdown d-inline-block mm">
              <button
                ref={profileDropRef}
                type="button"
                className={
                  showProfileDropdown
                    ? "btn header-item waves-effect show"
                    : "btn header-item waves-effect"
                }
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => setShowProfileDropDown(!showProfileDropdown)}
              >
                {authDetails.companyData?.member?.imageUrl ? (
                  <img
                    className="rounded-circle header-profile-user"
                    src={IMAGE_URL + authDetails.companyData?.member?.imageUrl}
                    alt=""
                  />
                ) : (
                  <span className="avName">
                    <span className="proName">
                      {getInitials(
                        authDetails.companyData?.member?.firstName,
                        authDetails.companyData?.member?.lastName
                      )}
                    </span>
                  </span>
                )}
                <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                  {authDetails.companyData?.member?.firstName}{" "}
                  {authDetails.companyData?.member?.lastName}
                </span>
                <i className="fas fa-chevron-down d-none d-xl-inline-block user-dropdown"></i>
              </button>
              <div
                className={
                  showProfileDropdown
                    ? "dropdown-menu dropdown-menu-end show"
                    : "dropdown-menu dropdown-menu-end"
                }
              >
                {/* <!-- DEV--> */}
                {/* <div className="pl-4 pt-2 pb-2">
                <p>
                  {authDetails.companyData?.member?.firstName +
                    " " +
                    authDetails.companyData?.member?.lastName}
                </p>
                <p>{authDetails.companyData?.member?.email}</p>
              </div>
              <div className="dropdown-divider"></div> */}
                {/* <!-- DEV--> */}
                {authDetails.companyData?.isCamp && (
                  <>
                    <Link className="dropdown-item" to="/family-members">
                      <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
                      <span key="t-profile">Family Members/Children</span>
                    </Link>
                    <div className="dropdown-divider"></div>
                  </>
                )}
                <Link className="dropdown-item" to="/favorites">
                  <i className="bx bx-heart font-size-16 align-middle me-1"></i>{" "}
                  <span key="t-profile">Favorites</span>
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/explore">
                  <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
                  <span key="t-profile">Explore</span>
                </Link>
                <div className="dropdown-divider"></div>
                {/* <a className="dropdown-item" href="#">
                <i className="bx bx-wallet font-size-16 align-middle me-1"></i>{" "}
                <span key="t-my-wallet">My Wallet</span>
              </a>
              <a className="dropdown-item d-block" href="#">
                <span className="badge bg-success float-end">11</span>
                <i className="bx bx-wrench font-size-16 align-middle me-1"></i>{" "}
                <span key="t-settings">Settings</span>
              </a>
              <a className="dropdown-item" href="#">
                <i className="bx bx-lock-open font-size-16 align-middle me-1"></i>{" "}
                <span key="t-lock-screen">Lock screen</span>
              </a> */}

                <button
                  className="dropdown-item text-danger"
                  onClick={() => {
                    Auth.signOut()
                      .then((e) => {
                        dispatch(logoutUser());
                        dispatch(clearPublicUser());
                        dispatch(clearAppointmentBookingDetails());
                        dispatch(clearClassBookingDetails());
                        localStorage.clear();
                        history.replace("/login");
                        console.log(e);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }}
                >
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
                  <span key="t-logout">Logout</span>
                </button>
              </div>
            </div>

            {/* <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item noti-icon right-bar-toggle waves-effect"
            >
              <i className="bx bx-cog bx-spin"></i>
            </button>
          </div> */}
          </div>
        </div>
      </header>
      {!_.has(authDetails.companyData?.member, "toc") && (
        <div className="banner-container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-8 row my-4 justify-content-center d-flex">
              <div className="col d-flex">
                <p className="banner-text ml-4 font-size-16">
                  <RiErrorWarningLine size={25} color="white" /> To continue
                  booking with your coach, you must first accept CoachFirst’s
                  Terms & Conditions and Privacy Policy
                </p>
              </div>
              <div className="col-auto d-flex">
                <Button
                  danger
                  style={{ color: "black" }}
                  onClick={toggleTncModal}
                >
                  Click here
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        title="Please check the items and click on accept"
        open={tnc.isShowModal}
        onCancel={toggleTncModal}
        centered
        footer={
          <Button
            danger
            type="primary"
            style={{
              backgroundColor: PRIMARY_COLOR,
              opacity:
                !tnc.tnc18Plus || !tnc.tncPrivacy || !tnc.tncTerms ? "0.5" : 1,
              color: "#ffffff",
            }}
            disabled={!tnc.tnc18Plus || !tnc.tncPrivacy || !tnc.tncTerms}
            onClick={_acceptTnc}
            loading={tnc.isLoading}
          >
            Accept
          </Button>
        }
      >
        <AcceptTC setDispatchItems={setTncState} state={tnc} />
      </Modal>
    </>
  );
};

export default Header;
