/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:c715340f-63dd-4bb7-acfe-b95ab3a4b149",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_pWcBuSig4",
  aws_user_pools_web_client_id: "7hqfd30pli9tsd4kegtq8jh9n7",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://hloqlq3axba53d7qiascbwno4e.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_user_files_s3_bucket:
    "circadn5c3ef53d0ecb465fbc5b20293d2e9f5a04153-production",
  aws_user_files_s3_bucket_region: "us-east-1",
};

export default awsmobile;
