import {
  ClientDashboard,
  Booking,
  RescheduleAppointment,
  ConfirmBookingPage,
  FamilyMembers,
  PaymentPage,
  Favorites,
  Explore,
  Details,
  PostBookingConfirmation,
  Waiver,
} from "../pages";

const routeConfig = [
  {
    path: ["/", "/dashboard"],
    component: ClientDashboard,
    name: "Dashboard",
    exact: true,
  },
  { path: "/booking", component: Booking, name: "Booking", exact: true },
  {
    path: "/reschedule",
    component: RescheduleAppointment,
    name: "Reschedule",
    exact: true,
  },
  {
    path: "/confirm-booking",
    component: ConfirmBookingPage,
    name: "Confirm Booking",
    exact: true,
  },
  {
    path: "/family-members/:isBooking?",
    component: FamilyMembers,
    name: "",
    exact: true,
  },
  { path: "/explore", component: Explore, name: "Explore", exact: true },
  { path: "/favorites", component: Favorites, name: "Favorites", exact: true },
  { path: "/details/:companyId", component: Details, name: "", exact: true },
  { path: "/payment", component: PaymentPage, name: "Payment", exact: true },
  {
    path: "/booking-confirmation",
    component: PostBookingConfirmation,
    name: "Booking Confirmation",
    exact: true,
  },
  {
    path: "/waiver",
    component: Waiver,
    name: "Waiver",
    exact: true,
  },
];

export default routeConfig;
